import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Metadata from '../components/Metadata';
import PageLayout from '../layouts/Page';
import Hero from '../components/Hero';
import PancakesStack from '../components/PancakesStack';

const VideoParticipantsPage = (props) => {
  const { videoPage } = props.data;

  const videos = videoPage.pancakes.find(
    (pancake) => pancake._type === 'videosList'
  ).videos;

  const filteredVideos = videos.filter((video) =>
    video.participants.find(
      (participant) => participant.slug.current === props.pageContext.slug
    )
  );

  const participantName = filteredVideos[0].participants.find(
    (participant) => participant.slug.current === props.pageContext.slug
  ).name;

  videoPage.pancakes.find(
    (pancake) => pancake._type === 'videosList'
  ).filteredVideos = filteredVideos;

  return (
    <PageLayout>
      <Metadata
        title={`Videos with ${participantName}`}
        description={videoPage.seo?.metaDescription}
      />
      <Hero>
        <Hero.Title>
          Videos <span>by {participantName}</span>
        </Hero.Title>
      </Hero>
      <PancakesStack pancakes={videoPage.pancakes} />
    </PageLayout>
  );
};

export const query = graphql`
  query VideoParticipantsPage {
    videoPage: sanityLandingPage(slug: { current: { eq: "videos" } }) {
      _id
      pageTitle
      hero {
        heroText
        heroTitle
        heroOverlappingImage {
          altText
          image {
            ...ImageWithPreview
          }
        }
        heroCallToActions {
          _rawCallToActions
        }
        heroLogo {
          companyLogo {
            logoFullColorLight {
              asset {
                url
              }
            }
            logoMonochromeLight {
              asset {
                url
              }
            }
          }
          customLogo {
            altText
            image {
              asset {
                url
              }
            }
          }
        }
      }
      slug {
        current
      }
      seo {
        metaDescription
        metaTitle
        ogImage {
          asset {
            url
          }
        }
      }
      pancakes {
        ...SanityPancakeText
        ...SanityPancakeCards
        ...SanityPancakeTextImage
        ...SanityPancakePreFooter
        ...SanityPancakeFeaturesHighlight
        ...SanityPancakeCustomerQuote
        ...SanityPancakeExplore
        ...SanityPancakeVideo
        ...SanityTrySourceControlProvider
        ...SanityPancakeLogos
        ...SanityPancakeJobs
        ...SanityTeamPancake
        ...SanitySpeakersPancake
        ...SanityPancakeSchedule
        ...SanityPancakeHubspotForm
        ...SanityBlogPostsListing
        ...SanityPancakeVideosList
        ...SanityTabs
      }
    }
  }
`;

export default VideoParticipantsPage;
